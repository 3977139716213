import React from "react";
import instagram from "../../Assets/Image/instagram.png";
import facebook from "../../Assets/Image/facebook.png";
import twitter from "../../Assets/Image/twitter.png";
// import location from "../../Assets/Image/location.png";
import devqsoftLogo from "../../Assets/Image/devqsoftLogo.jpeg";
import Wave from "react-wavify";
import { PostData } from "../../Utils/firebaseServices";
import { Alert } from "react-bootstrap";
import Location from '../../Assets/Image/location(02).png'
import PhoneCall from '../../Assets/Image/phoneCall.png'
import EmailIcon from '../../Assets/Image/Email.png'

const Footer = () => {
  const [data, setData] = React.useState({});
  const [message, setMessage] = React.useState({ error: false, msg: "" });

  // upload data in fireBase
  const dataUploadInFireBase = (e) => {
    console.log(data?.email);

    const emailValid = data?.email?.match(
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
    e.preventDefault();

    if (data?.email) {
      if (!emailValid) {
        setMessage({ error: false, msg: "Invalid Email Format!" });
        return;
      }
      const userData = {
        ...data,
      };
      PostData(userData, "subscribe_users", responsePostData);
    } else {
      setMessage({ error: false, msg: "Please enter a valid email address!" });

      return;
    }
  };

  // response After Submit data
  const responsePostData = (res) => {
    if (res?.id) {
      setData({
        email: "",
      });
      setMessage({
        error: true,
        msg: "Thank you for filling out your information!",
      });
      setTimeout(() => {
        setMessage("");
      }, 3000);
    } else {
      setMessage({ error: false, msg: "Sorry , Network Error" });
    }
  };
  return (
    <div style={{ position: "relative" ,}}>
      <div className="footer">
        
        <div className="Footer-Container">
          <div className="footer-logo" >
            <h2>dev<span style={{color: "red"}}>Q</span>soft</h2>
            <p>A Complete solution for designing and developing of <br /> any Apps .“Innovate, Create, Connect: Empowering <br /> the Digital World through App Development.</p>
          </div>
          
          <div >
            <div className="useful-link">
              <h2>Useful Links</h2>

              <div className="use-links">
                <li>
                  <a href="/"> Home</a>
                </li>
                <li>
                  <a href="/aboutUs"> About Us</a>
                </li>
                <li>
                  <a href="/contactUs"> Contact Us</a>
                </li>
              </div>
            </div>
          </div>

          <div >
            <div className="social-links">
              <h2>Follow Us</h2>

              <div className="social-icons">
                <li>
               
                  <a href="" style={{display:"flex", justifyContent:"center" ,alignSelf:"center",flexDirection:"column"}} >
                  <center>
                    <img
                      src={instagram}
                      height="25"
                      className="me-2  imgHover"
                    />
                    </center>
                    Instagram
                  </a>
                </li>
                <li>
                  <a href="" style={{display:"flex", justifyContent:"center" ,alignSelf:"center",flexDirection:"column"}}>
                  <center>
                    <img
                      src={facebook}
                      height="25"
                      className="me-2  hover imgHover"
                    />
                    </center>
                    Facebook
                  </a>
                </li>
                <li>
                  <a href="" style={{display:"flex", justifyContent:"center" ,alignSelf:"center",flexDirection:"column"}}>
                  <center>
                    <img src={twitter} height="25" className="me-2 imgHover" />
                    </center>
                    Linkedin
                  </a>
                </li>
              </div>
            </div>
          </div>

          <div >
            <div className="address">
              <h2>Address</h2>

              <div className="address-links">
                <li className="address1">
                  <img src={Location} alt="locatin-icon" height="22" className="me-2" /> 401, Arcade Silver, 56 Dukan, <br /> New Palasia, Indore, Madhya Pradesh, 452001
                </li>
                <li className="address1">
                   <img src={PhoneCall} alt="PhoneCall-icon" height="20" className="me-2" /> +91 7313137270
                 
                </li>
                <li className="address1">
                 
                    <img src={EmailIcon} alt="Email-icon" height="20" className="me-2" /> devqsoft@gmail.com
                 
                </li>
              </div>
            </div>
            <div className="KEEP-IN-TOUCH"
              style={{
                alignSelf: "center m-0",
                marginBottom: 10,
                marginTop: 10,
              }}
            >
              <h2
                className="section"
                style={{
                  fontSize: 20,
                  fontWeight: "600",    
                  marginBottom: 5,
                  marginTop: 15,
                  color: " #04a5bf",
                }}
              >
                KEEP IN TOUCH WITH US
              </h2>
              <p style={{fontWeight: 450 , color: "#777777" , marginBottom: 0}}>
                Information about current events related <br />
                to our company
              </p>
              <div className="message_alert">
                {message?.msg && (
                  <Alert
                    variant={message?.error ? "success" : "danger"}
                    dismissible
                    onClose={() => setMessage("")}
                  >
                    
                    {message?.msg}
                  </Alert>
                )}
              </div>

              <input
                name="email"
                type="email"
                placeholder=" e-mail"
                className="email_input"
                value={data?.email}
                onChange={(e) => {
                  setData({ email: e.target.value });
                  setMessage("");
                }}
              />
              <button
                type="submit"
                value="SEND"
                id="submit"
                name="submit_btn"
                className=" sumbit_button"
                onClick={dataUploadInFireBase}
              >
                Submit
              </button>
            </div>
          </div>
        </div>

        <div class="copy-right-sec">  
				Copyright © DevQsoft. 2022-All reserved. 
			</div>
      </div>
      <Wave
        fill="#000"
        paused={false}
        opacity="0.9"
        options={{
          height: 0,
          amplitude: 10,
          speed: 0.3,
          points: 7,
        }}
        style={{ height: 460, position: "absolute", bottom: 0, zIndex: -1 }}
        className="footer_Bg"
      />
      <Wave
        fill="#000"
        paused={false}
        opacity="0.3"
        options={{
          height: 0,
          amplitude: 10,
          speed: 0.3,
          points: 7,
        }}
        style={{ height: 470, position: "absolute", bottom: 0, zIndex: -1 }}
        className="footer_Bg"
      />
    </div>
  );
};

export default Footer;
