import React from "react";
import backGImg from "../../Assets/Image/pngwing_com.png"
import bannerImg from "../../Assets/Image/bannerImg.jpg"

const Header = () => {
  return (
    <>
      <div style={{
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        width: "100%",
        flexWrap: "wrap"
      }} className='animatio_banner'>
        <div className="text_rounded">
          <div className="p-0 , mb-4" style={{ color: "#fff" }}>
            <div style={{ fontWeight: "400", fontSize: 24, }}><span style={{ color: "rgb(10 249 227)" }}>&#10077;</span> CREATING STATE OF THE ART</div>
            <div style={{ fontWeight: "700", fontSize: 26 }}>WEB <span style={{ color: "rgb(10 249 227)" }}>&</span> MOBILE USERS EXPERIENCE <span style={{ color: "rgb(10 249 227)", fontSize: 24, fontWeight: "400" }}>&#10078;</span></div>
          </div>

          <div className="p-0 , mb-4" style={{ color: 'rgb(10 249 227)' }}> Design | Innovation | Exprerince</div>

          <div style={{ fontWeight: "700", fontSize: 18, color: "#fff" }}> <span style={{ color: "rgb(10 249 227)" }}>&#10077;</span> BUILDING A NEW WORLD OF IDEATION <span style={{ color: "rgb(10 249 227)" }} >&#10078;</span></div>

        </div>
        <img src={backGImg} maxHeight="30%" className="imageBanner" maxWidth="30%"  />


      </div>
    </>
  )

}

export default Header;