import React, { useEffect, useState } from 'react';
import './ServiceComponent.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop, faCode, faPalette, faCog, faPaintBrush, faVideo, faChartBar, faBullhorn } from '@fortawesome/free-solid-svg-icons';
import service1 from "../Assets/Image/service-1.png";
import service2 from "../Assets/Image/service-2.png";
import service3 from "../Assets/Image/service-3.png";
import service4 from "../Assets/Image/service-4.png";
import Footer from './Home/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import fast_forward from "../Assets/Image/fast_forward.png";

const Service = () => {
  const [isVisible, setIsVisible] = useState(false);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
   
    goToTop();

    
    AOS.init({
      duration: "1000",
      delay: '100'
    });
  }, []);


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 450) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const iconSize = '3x';
  return (
    <>

      <section className="call-to-action">
        <div className="container5 text-center" data-aos="zoom-out" style={{ display: "flex", flexDirection: "column" }}>
          <a href="https://www.youtube.com/watch?v=LXb3EKWsInQ" className="glightbox play-btn"></a>
          <h3>Call To Action</h3>
          <p className='textcolor'>
            To get your prospects to do what you want, it helps to include a compelling call to action on your website and in your marketing campaigns.
            To get your prospects to do what you want, it helps to include a compelling call to action on your website and in your marketing campaigns.
          </p>
          <a to="#" className="cta-btn">Call To Action</a>
        </div>
      </section>



      <div className="service">
        <div className='backgrouncontainer'>
          <div className="container-custom"  >
            <div className="section-header">
              <h2>Our Services</h2>
              <p >Frontend developers specialize in designing and implementing the visual elements and user interface of websites or applications. Using HTML, CSS, and JavaScript, they create responsive, engaging, and user-friendly interfaces that enhance the overall user experience. Their role involves collaborating with design teams and integrating backend functionalities to ensure seamless and intuitive navigation for users</p>
            </div>
            <div className="row " data-aos="zoom-in-up" data-aos-delay="100">
              {servicesData.map((service, index) => (
                <div key={index} className="col-lg-3 col-md-6">
                  <div className="service-item zoom-on-hover" >
                    <FontAwesomeIcon
                      icon={service.icon}
                      className={`service-icon fa-${iconSize}`}
                      style={{ transition: 'color 0.3s ease-in-out', zIndex: 11 }}
                    />
                    <h3 className='textupade underline-on-hover'>{service.title}</h3>
                    <hr
                      style={{
                        height: '5px',
                        backgroundColor: 'red',

                      }}
                      className="hr-on-hover"
                    ></hr>
                    <p>{service.description}</p>
                    <button className="read-more-button">Read More</button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>


        <div className="service-row custom-width" data-aos="zoom-out-up" data-aos-delay="100">
          <div className="containerimage" >
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="service-row-text" data-aos="fade-down" data-aos-delay="100">
                  <h2 className="section-title">Web Design</h2>
                  <p>
                    Web design is a multifaceted discipline encompassing the creation and arrangement of visual elements, user experience considerations, and collaboration with web developers. It involves graphic design for aesthetics, user interface design for intuitive interaction, and responsive design to ensure adaptability across various devices. A web designer structures the layout, selects typography, and chooses color schemes to align with the brand identity. They prioritize user-friendly navigation and engage in continuous testing and iteration to enhance the website's functionality. Successful web design requires a harmonious blend of creativity, technical proficiency, and a deep understanding of user behavior to deliver an appealing and effective online presence.
                  </p>
                  <a className="btn" href="">
                    Read More
                  </a>
                </div>
              </div>
              <div className="col-md-6 d-md-block d-none">
                <div className="service-row-img">
                  <img src={service1} alt="Service" className="zoom-on-hover" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="service-row custom-width" data-aos="zoom-out-down" data-aos-delay="100">
          <div className="containerimage">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="service-row-img">
                  <img src={service2} alt="Service" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="service-row-text" data-aos="fade-up" data-aos-delay="100">
                  <h2 className="section-title">Web Development</h2>
                  <p>

                    Web development involves creating and maintaining websites or web applications. It encompasses front-end development, focusing on the user interface and experience, and back-end development, handling server-side functionalities. Web developers use languages like HTML, CSS, JavaScript for front-end, and technologies like PHP, Python, or Node.js for back-end. They work with databases, server configurations, and frameworks to build dynamic and interactive websites. Collaboration between web designers and developers is crucial to ensure a seamless translation of design into functional code. Web development also involves continuous testing, debugging, and optimization to deliver secure, responsive, and efficient online solutions that meet user needs.
                  </p>
                  <a className="btn" href="">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="service-row custom-width" data-aos="zoom-out-up" data-aos-delay="100">
          <div className="containerimage">
            <div className="row align-items-center">
              <div className="col-md-6 d-md-none d-block">
                <div className="service-row-img">
                  <img src={service3} alt="Service" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="service-row-text" data-aos="fade-down" data-aos-delay="100" >
                  <h2 className="section-title">Web Security</h2>
                  <p>
                    Web security involves implementing measures to protect websites and web applications from unauthorized access, data breaches, and malicious attacks. It includes employing secure coding practices, using encryption protocols (HTTPS), and implementing authentication mechanisms to ensure only authorized users access sensitive information. Web security also involves protecting against common vulnerabilities like SQL injection, cross-site scripting (XSS), and cross-site request forgery (CSRF). Regular security audits, monitoring, and updates are essential to identify and address potential vulnerabilities. Additionally, the use of firewalls, intrusion detection systems, and secure server configurations contribute to maintaining a robust defense against evolving cyber threats and safeguarding the integrity and confidentiality of web-based systems.
                  </p>
                  <a className="btn" href="">Read More</a>
                </div>
              </div>
              <div className="col-md-6 d-md-block d-none">
                <div className="service-row-img">
                  <img src={service3} alt="Service" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="service-row custom-width" data-aos="zoom-out-up" data-aos-delay="100">
          <div className="containerimage">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="service-row-img"   >
                  <img src={service4} alt="Service" style={{marginBottom:"100px" }} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="service-row-text" data-aos="fade-up" data-aos-delay="100">
                  <h2 className="section-title">Online Marketing</h2>
                  <p>

                    Online marketing, also known as digital marketing, involves promoting products or services through internet channels to reach a target audience. It includes strategies like search engine optimization (SEO), social media marketing, email marketing, content marketing, and paid advertising. Online marketers leverage various platforms, such as search engines, social media networks, and websites, to create brand awareness, drive traffic, and generate leads or sales. The goal is to engage with potential customers, build relationships, and increase the online presence of a business or brand. Analytics and data-driven insights play a crucial role in refining and optimizing online marketing campaigns for better results.
                  </p>
                  <a className="btn" href="">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        {isVisible &&
        <div id='btn-back-to-top'>
          <img src={fast_forward} alt="Back to Top" style={{
            height: 30, width: 30, resizeMode: 'contain', borderRadius: 100,
          }} />
        </div>
      }
      </div>
    </>
  );
};

export default Service;


const servicesData = [
  {
    icon: faDesktop,
    title: "Web Design",
    description: "Web design is the process of creating visually appealing and user-friendly websites. It encompasses graphic design, layout structuring, responsive design, and collaboration with developers, aiming for an optimal user experience."
  },

  {
    icon: faCode,
    title: "Development",
    description: " Development is the process of coding and building software or websites, encompassing design, testing, and deployment. It aims to create functional, user-friendly solutions meeting specific needs and requirements."
  },

  {
    icon: faPalette,
    title: "UI Design",
    description: "UI (User Interface) design focuses on creating visually appealing and intuitive interfaces for software or websites, emphasizing user experience through elements like layout, colors, and interactive components."
  },
  {
    icon: faCog,
    title: "Programming",
    description: "Programming involves writing and designing instructions for computers to execute. It encompasses various languages like Python, Java, and C++, enabling the development of software, applications, and websites."
  },
  {
    icon: faPaintBrush,
    title: "Graphic Design",
    description: "Graphic design is the creation of visual content using elements like typography, imagery, and layout. It communicates messages and enhances aesthetics in various mediums, including print and digital platforms."
  },
  {
    icon: faVideo,
    title: "Video Editing",
    description: "Video editing involves manipulating and arranging video footage, adding effects, transitions, and audio to create a cohesive and engaging final product for various purposes, including entertainment or informational content."
  },
  {
    icon: faChartBar,
    title: "SEO",
    description: "SEO (Search Engine Optimization) is the practice of optimizing websites to improve their visibility on search engines. It involves strategies to enhance rankings, increase organic traffic, and improve online presence."
  },
  {
    icon: faBullhorn,
    title: "Online Marketing",
    description: "Online marketing, also known as digital marketing, involves promoting products or services through various online channels, such as social media, search engines, email, and websites, to reach and engage a target audience."
  },
];
