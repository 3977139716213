import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { auth } from "../../FirebaseConfig";
import { LoginFireBase } from "../../Utils/firebaseServices";




const Login = () => {
    let navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = React.useState({ error: false, msg: '' });

    useEffect(() => {
        const Auth = localStorage.getItem('admin')
        if (Auth) {
            navigate('/adminHome')
        }
    }, [])

    // const  = () => {
    //    
    // }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (email && password) {

            signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    // Signed in
                    const user = userCredential.user;
                    localStorage.setItem('admin', JSON.stringify(user?.reloadUserInfo));
                    navigate('/adminHome')
                    console.log('user', user?.reloadUserInfo);
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log('errorCode=-=-=-=', errorCode, errorMessage)
                    setMessage({ error: false, msg: errorCode });

                });
        } else {
            setMessage({ error: false, msg: "All field are mandatory!" });
        }


    }

    return (
        <>

            <div className="Auth-form-container">

                <form className="Auth-form">
                    {message?.msg && (<Alert variant={message?.error ? "success" : 'danger'} dismissible onClose={() => setMessage('')}>
                        {' '}
                        {message?.msg}
                    </Alert>)}
                    <div className="Auth-form-content">
                        <h3 className="Auth-form-title">Log In</h3>
                        <div className="form-group mt-3">
                            <label>Email address</label>
                            <input
                                type="email"
                                className="form-control mt-1"
                                placeholder="Enter email"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-3">
                            <label>Password</label>
                            <input
                                type="password"
                                className="form-control mt-1"
                                placeholder="Enter password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className="d-grid gap-2 mt-3">
                            <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
                                Submit
                            </button>
                        </div>
                        <p className="forgot-password text-right mt-2">
                            Forgot <a href="#">password?</a>
                        </p>
                    </div>
                </form>
            </div>
        </>

    )
};
export default Login