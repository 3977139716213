import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import phone_book from "../Assets/Image/phone_book.png";
import { PostData } from "../Utils/firebaseServices";
import Footer from "./Home/Footer";
import fast_forward from "../Assets/Image/fast_forward.png"
const Contect = () => {
  const [data, setData] = React.useState({});
  const [message, setMessage] = React.useState({ error: false, msg: "" });
  const [isVisible, setIsVisible] = useState(false);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
   
    goToTop();

    
  
  }, []);


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 450) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  // upload data in fireBase
  const dataUploadInFireBase = (e) => {
    const emailValid = data?.email && data?.email.match(
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
    e.preventDefault();
  
    if (data?.name && data?.email && data?.phone && data?.about) {
      if (!emailValid) {
        setMessage({ error: false, msg: "Invalid Email Format!" });
        return;
      }
      const userData = {
        ...data,
      };
      PostData(userData, "clients", responsePostData);
    } else {
      setMessage({ error: false, msg: "All fields are mandatory!" });
      return;
    }
  };
  

  // response After Submit data
  const responsePostData = (res) => {
    if (res?.id) {
      setData({
        name: "",
        email: "",
        phone: "",
        about: "",
      });
      setMessage({
        error: true,
        msg: "Thank you for filling out your information!",
      });
      setTimeout(() => {
        setMessage("");
      }, 3000);
    } else {
      setMessage({ error: false, msg: "Sorry , Network Error" });
    }
  };
  return (
    <div>
      <div className="contect_banner ">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ background: "#ffffff", borderRadius: 50 }}>
            <img
              src={phone_book}
              style={{ objectFit: "contain", padding: 20, height: 80 }}
            />
          </div>

          <h1 style={{ color: "#4c5867", textAlign: "center", fontSize: 80 }}>
            {" "}
            Contact Us
          </h1>
          <div
            class="page-title-excerpt"
            style={{
              color: "#4c5867",
              marginTop: 15,
              textAlign: "center",
              fontSize: 19,
              fontWeight: "300",
            }}
          >
            Please let us know how we can help?
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          textAlign: "center",
          justifyContent: "center",
          marginTop: 50,
          flexDirection: "column",
          marginBottom:"150px"
        }}
      >
        <h2 >Get in touch</h2>

        <p className="lead" >
          Let us give you more details about the special offer website you want
          us. Please fill out the form below. <br />
          We have million of website owners who happy to work with us!
        </p>
        <div className="contect_maps" style={{marginBottom:"-100px"}}>
          <div className="contact_form">
            {message?.msg && (
              <Alert
                variant={message?.error ? "success" : "danger"}
                dismissible
                onClose={() => setMessage("")}
              >
                {" "}
                {message?.msg}
              </Alert>
            )}
            <div className=" ">
              <input
                type="text"
                name="first_name"
                id="first_name"
                className="form-control"
                placeholder="Full Name *"
                value={data?.name}
                onChange={(e) => {
                  setData({ ...data, name: e.target.value });
                  setMessage("");
                }}
              />
            </div>

            <div className="">
              <input
                type="email"
                name="email"
                id="email"
                className="form-control"
                placeholder="Your Email *"
                value={data?.email}
                onChange={(e) => {
                  setData({ ...data, email: e.target.value });
                  setMessage("");
                }}
              />
            </div>
            <div className="">
              <input
                type="number"
                name="phone"
                id="phone"
                className="form-control"
                placeholder="Your Phone *"
                value={data?.phone}
                onChange={(e) => {
                  setData({ ...data, phone: e.target.value });
                  setMessage("");
                }}
              />
            </div>
            <div className="">
              <textarea
                className="form-control_comment"
                name="comments"
                id="comments"
                rows="6"
                placeholder="About your project *"
                value={data?.about}
                onChange={(e) => {
                  setData({ ...data, about: e.target.value });
                  setMessage("");
                }}
              />
            </div>

            <button
              type="submit"
              value="SEND"
              id="submit"
              name="submit_btn"
              className="btn-light "
              onClick={dataUploadInFireBase}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <Footer />
      {isVisible &&
        <div id='btn-back-to-top'>
          <img src={fast_forward} alt="Back to Top" style={{
            height: 30, width: 30, resizeMode: 'contain', borderRadius: 100,
          }} />
        </div>
      }
    </div>
  );
};

export default Contect;
