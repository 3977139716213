import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function NavBar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleNavClick = () => {
    setExpanded(false);
  };

  return (
    (location.pathname !== '/admin' && location.pathname !== '/adminHome') ? (
      <Navbar expanded={expanded} onToggle={handleToggle} collapseOnSelect expand="lg" bg="light" variant="light" fixed="top" className='nav_box shadow-sm'>
        <Container fluid className='containerDiv'>
          <Navbar.Brand style={{ color: "#1da4e6", fontSize: 26, fontWeight: "700" }} className='hover_effect' onClick={() => navigate('/')}>
            dev<span>&#8474;</span>soft
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              {/* Add NavDropdown or other Nav elements if needed */}
            </Nav>
            <Nav onClick={handleNavClick}>
              <NavLink className={`pr-30 pr-lg-0 nav_bar_sty`} to="/" activeClassName="active" exact>Home</NavLink>
              <NavLink className={`pr-30 pr-lg-0 nav_bar_sty`} to="/AboutUs" activeClassName="active">About</NavLink>
              <NavLink className={`pr-30 pr-lg-0 nav_bar_sty`} to="/Services" activeClassName="active">Services</NavLink>
              <NavLink className={`pr-30 pr-lg-0 nav_bar_sty`} to="/carrers" activeClassName="active">Carrers</NavLink>
              <NavLink className={`pr-30 pr-lg-0 nav_bar_sty`} to="/contactUs" activeClassName="active">Contact Us</NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    ) : (
      <Navbar collapseOnSelect expand="lg" bg="light" variant="light" fixed="top" className='nav_box shadow-sm admin'>
        <Container fluid className='containerDiv'>
          <Navbar.Brand style={{ color: "#1da4e6", fontSize: 26, fontWeight: "700", textAlign: "center" }} className='hover_effect' onClick={() => navigate('/')}>
            dev<span>&#8474;</span>soft
          </Navbar.Brand>
        </Container>
      </Navbar>
    )
  );
}

export default NavBar;
