import React, { useEffect, useState } from "react";
import Footer from "../Home/Footer"
import imgAbout from "../../Assets/Image/about-(02).jpg";
import WebDesignLogo from "../../Assets/Image/Web-Design-logo.png";
import webDevelopLogo from "../../Assets/Image/web-development-logo.png";
import AppDevelopLogo from "../../Assets/Image/app-development.png";
import fast_forward from "../../Assets/Image/fast_forward.png"
import javaLogo from "../../Assets/about-img-logo/java.webp";
import mobileLogo from "../../Assets/about-img-logo/mobile.webp"
import webLogo from "../../Assets/about-img-logo/web.webp"
import webDesign from "../../Assets/about-img-logo/web_design.webp"
import eLogo from "../../Assets/about-img-logo/ecommerce.webp"
import DigitalLogo from "../../Assets/about-img-logo/digital.webp"
import AOS from 'aos';
import 'aos/dist/aos.css';
const AboutUs = () => {

  const [isVisible, setIsVisible] = useState(false);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
   
    goToTop();

    
    AOS.init({
      duration: "1000",
      delay: '100'
    });
  }, []);


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 450) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  useEffect(()=>{
    AOS.init({duration:"1000",
 delay:'100'}) 
 })
 

  return (
    <>
      <div className="About-heading-img">
        <div style={{ textAlign: "center", paddingTop: "10%" }}>
          <h1 className="about-h1">
            {" "}
            <span className="about-heading">About</span> DevQsoft
          </h1>
          <p className="About-text">
            We facilitate the smooth navigation of high-impact, end-to-end
            digital <br /> transformation for organizations of all sizes.
          </p>
        </div>
      </div>
      <div className="moving-text-container" >
      <div className="moving-text">devQsoft Technology</div>
    </div>
      <div className="who-we-Are">
        <div className="image_width" >
          <img className="Who-We-img" src={imgAbout} alt=""data-aos="fade-up"/>
        </div>

        <div>
          <h2 className="Heading-h2"  style={{textAlign:"start",}}>
            Who <span style={{ color: "black" }}>We Are ?</span>
          </h2>
          <p className="About-p" data-aos="fade-down" style={{textAlign:"start",fontSize:"18px"}}>
            We are believers of change! A change driven by technology and
            innovation. We help businesses and individuals in adapting as
            well as adopting digital transformation. Our aim is to change
            people’s lives and improve businesses with our progressive
            and innovative technology solutions. We believe in delivering
            quick and efficient IT solutions to help you scale up your business
            effectively. We believe in long-term partnerships and all our
            clients have been with us from start to finish.
          </p>
        </div>
      </div>

      <div className="Services-heading">
        <h2 className="heading fs-25 mb-1" data-aos="fade-down" >Services We Provide</h2>
        <center> <div style={{ height: 3, backgroundColor: "#dd9933", width:"80%", marginTop: 30, marginBottom: 30 }} />
        <p data-aos="fade-up" style={{width:"80%"}}>
        Delivering comprehensive digital services encompassing web design,development, digital marketing,
        and more, we specialize in creating tailored solutions to enhance your online presence and drive results
        . With a focus on innovation and client satisfaction, we're dedicated to helping you achieve your 
        business objectives in the ever-evolving digital landscape.</p></center>
      </div>

      <div className="Service-card">
        <div className="Services-contaner">
          <div className="Service-logo-box">
            <img className="Card-logo-img" src={WebDesignLogo} alt="" />
          </div>
          <div className="service-contant">
            <h2>Web Design</h2>
            <p style={{textAlign:"start",}}>
              No matter what your business size is, We at Creative Thoughts help
              you in designing the best website that represents your business
              aptly. Count in logos, visuals, branding or anything under the
              roof, our team of experts helps you out using the latest
              technologies available
            </p>
          </div>
        </div>

        <div className="Services-contaner">
          <div className="Service-logo-box">
            <img className="Card-logo-img" src={webDevelopLogo} alt="" />
          </div>
          <div className="service-contant">
            <h2>Web Development</h2>
            <p style={{textAlign:"start",}}>
              We make you stand out from the rest with powerful branding. We
              have come up with a team of experienced developers who are
              literally passionate to develop websites. Not only they utilize
              the best and latest development methods,  but also make sure that
              your website serves its purpose.
            </p>
          </div>
        </div>
        
        <div className="Services-contaner">
          <div className="Service-logo-box">
            <img className="Card-logo-img" src="https://png.pngtree.com/png-vector/20190611/ourmid/pngtree-web-development-illustration-modern-can-be-used-for-landing-pages-web-png-image_1496210.jpg" alt="" />
          </div>
          <div className="service-contant">
            <h2>App Development</h2>
            <p  style={{textAlign:"start",}}>
            Our expertise lies in crafting cutting-edge mobile applications that prioritize user experience, leveraging the latest technologies to drive engagement and success in the ever-evolving digital ecosystem.
            </p>
          </div>
        </div>

        <div className="Services-contaner">
          <div className="Service-logo-box">
            <img className="Card-logo-img" src={AppDevelopLogo} alt="" />
          </div>
          <div className="service-contant">
            <h2>Application Development</h2>
            <p  style={{textAlign:"start",}}>
              Are you a business looking for custom application development
              solutions to support your individual business needs? If yes, we
              help empower you as our client with our proven development
              techniques. We also believe in industry's best practices to create
              an exclusive application.
            </p>
          </div>
        </div>


        
      </div>

      <div className="callout-section" style={{margin:"25px"}}>
        <div className="Callout-Container">
          <div className="Callout-section-heading" style={{padding:"35px"}}>
            <h2 className="callOutH2">
              We Provide High Quality, Yet Cost-Effective Solutions
            </h2>
            <p className="callOut-P">
              Our vision is to accelerate our customer's progress and directly
              contribute to their success by providing simple, intuitive web
              design and development services.  <span className="text_hide">We are an agile organization
              constantly striving to exceed expectations and be the best at what</span>
              we do.
            </p>
          </div>

          <div className="allcircles widthCard">
            <ul>
              <li>
                {" "}
                <div className="circle1 circleapp appcircle">
                  <img
                    src={javaLogo}
                    alt="javalogo"
                    className="wps-ic-cdn img-fluid ic-fade-in"
                  />
                </div>
              </li>
              <li>
                <div className="circle1 circleapple">
                  <img src={mobileLogo} alt="mobileLogo" className="wps-ic-cdn img-fluid ic-fade-in" />
                </div>
              </li>
              <li>
                <div className="circle1 circle1small circlenotepad">
                  <img src={webLogo} alt="webLogo" className="wps-ic-cdn img-fluid ic-fade-in" />
                </div>
              </li>
              <li>
                <div className="circle1 circlebook">
                  <img src={webDesign} alt="" className="wps-ic-cdn img-fluid ic-fade-in" />
                </div>
              </li>
              <li>
                <div className="circle1 circle1small2 circlechat">
                  <img src={eLogo} alt="Ecommerce" className="wps-ic-cdn img-fluid ic-fade-in"/>
                </div>
              </li>
              <li>
                <div className="circle1 circle1small circlecoffe">
                  <img src={DigitalLogo} alt="Digital" className="wps-ic-cdn img-fluid ic-fade-in"/>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="empty-section" style={{paddingBottom:"50x"}}></div>

      <Footer/>
      {isVisible &&
        <div id='btn-back-to-top'>
          <img src={fast_forward} alt="Back to Top" style={{
            height: 30, width: 30, resizeMode: 'contain', borderRadius: 100,
          }} />
        </div>
      }
    </>
  );
};

export default AboutUs;
