import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import useScrollListener from "../Common/UseScrollListener";
import AdminMain from "../Components/Admin/AdminMain";
import Login from "../Components/Admin/Login";
import Carrers from "../Components/Carrers";
import Contect from "../Components/Contect";

import Home from "../Components/Home/Home";
import NavBar from "../Components/NavBar";
import Services from "../Components/Service";
import AboutUs from "../Components/Home/AboutUs";

function MainRoutes() {
  const [navClassList, setNavClassList] = React.useState([]);
  const scroll = useScrollListener();
  // const Router = BrowserRouter();

  // update classList of nav on scroll
  React.useEffect(() => {
    const _classList = [];

    if (scroll.y > 150 && scroll.y - scroll.lastY > 0)
      _classList.push("nav-bar--hidden");

    setNavClassList(_classList);
  }, [scroll.y, scroll.lastY]);
  return (
    <BrowserRouter>  
      <nav className={navClassList.join(" ")}>
        <NavBar />
        <div style={{ height: 70 }} />
      </nav>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/*" element={<Home />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/carrers" element={<Carrers />} />
        <Route path="/Services" element={<Services /> }/>
        <Route path="/contactUs" element={<Contect />} />
        <Route path="/adminHome" element={<AdminMain />} />
        <Route path="/admin" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default MainRoutes;
