import React, { useEffect, useState } from 'react';

import Header from './Header';
import Footer from './Footer';
import fast_forward from "../../Assets/Image/fast_forward.png"
import BusinessStrategy from '../BusinessStrategy';
import BACKGROUND from "../../Assets/Image/AboutUsShadow.png"
import Carousel from './ImageScroll/Carousel';
import Bgvideo from '../Bgvideo/Bgvideo';

function Home() {
  const [isVisible, setIsVisible] = useState(false);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
   
    goToTop();

    
   
  }, []);


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 450) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div style={{
        display: 'flex',
        flexDirection: "column"
      }}>
        <Header />
        <BusinessStrategy />

        <div style={{ textAlign: "center", position: 'relative', overflow: 'hidden' }}>
     
          <div style={{ zIndex: 1, position: 'relative' }}>
            <h2 class="rtin-title">Our Range Of Services</h2>
            <p class="sub-text" style={{ width: '70%', padding: '20px', borderRadius: '8px', margin: '0 auto' }}>
              We never say no to any technologies, we do services to our clients with the help of our technical partners, when inhouse expertise is not available on specific technologies. With this unique approach, we pursue our clients. We develop purposeful solutions to solve real problems of customers' business.
            </p>
          </div>
          <Bgvideo />
          <Carousel />
        </div>
        {/* <img src={BACKGROUND} style={{ display: 'none' }} alt="background" /> */}
        <Footer />

        {isVisible &&
        <div id='btn-back-to-top'>
          <img src={fast_forward} alt="Back to Top" style={{
            height: 30, width: 30, resizeMode: 'contain', borderRadius: 100,
          }} />
        </div>
      }
      </div>
    </>
  );
}

export default Home;
