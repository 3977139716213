import { db, storage } from "../FirebaseConfig";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import { collection, getDocs, getDoc, addDoc, updateDoc, deleteDoc, doc } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

// class dataService {

//     PostData = (data, collectionName) => {
//         const collectionRef = collection(db, collectionName);

//         return addDoc(collectionRef, data);
//     };

//     updateData = (id, data, collectionName) => {
//         const updateCollection = doc(db, collectionName, id)

//         return updateDoc(updateCollection, data);
//     };

//     deleteData = (id, collectionName) => {
//         const deleteDataCollection = doc(db, collectionName, id)
//         return deleteDoc(deleteDataCollection)
//     };
//     getAllData = (collectionName) => {
//         const collectionRef = collection(db, collectionName);

//         return getDocs(collectionRef);
//     };

//     getdata = (id, collectionName,) => {
//         const getDataCollection = doc(db, collectionName, id)
//         return getDoc(getDataCollection);
//     };


// }

// upload Image 
export const uploadImageFireBase = (file, call_back, call_backPercent) => {
    const storageRef = ref(storage, `files/${file.name}`);

    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on("state_changed",
        (snapshot) => {
            const progress =
                Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            call_backPercent(progress);
        },
        (error) => {
            alert(error);
        },
        () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                call_back(downloadURL)
            });
        }
    );
};

// post Data In FireBase

export const PostData = async (data, collectionName, call_back) => {
    console.log("data Post ==", data);
    const collectionRef = collection(db, collectionName);
    const res = await addDoc(collectionRef, data)
    call_back(res);
};

// get Data In FireBase

export const getAllData = async (collectionName, call_back) => {
    let array = []
    console.log("collectionName Post ==", collectionName);
    const collectionRef = collection(db, collectionName);
    const res = await getDocs(collectionRef);

    res.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data());
        array.push(doc.data())
    });

    call_back(array);
};

export const LoginFireBase = (email, password) => {
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)

        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            // ...
            console.log('userLogin', user);
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log('errorCode==', errorCode);
            console.log('errorMessage==', errorMessage);
        });
}
