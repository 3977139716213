import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllData } from '../../Utils/firebaseServices';
import SideNavBar from './SideNavBar';
import CustomTable from './Table';

const AdminMain = () => {
    let navigate = useNavigate();

    const [carrers_data, setCarrers_data] = useState([])
    const [clients_data, setclients_data] = useState([])
    const [tab, setTab] = useState(1)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getAllData("carrers", get_carrers_dataRes);
        getAllData("clients", clients_dataRes);
    }, [])

    const get_carrers_dataRes = (res) => {
        setCarrers_data(res)
    }
    const clients_dataRes = (res) => {
        setclients_data(res)
    }
    const TabChange = (tab) => {

        setTab(tab)
    }

    useEffect(() => {
        setLoading(true)
        const auth = localStorage.getItem('admin')
        console.log("00-=-=-00009", auth);
        if (auth === null) {
            setLoading(false)
            navigate('/admin')
        }else{

            setLoading(false)
        }

    }, [])

    if(loading){

        return(
            <></>
        )
    }else{

    return (
        <>
            <div style={{ display: "flex" }}>
                <SideNavBar
                    TabChange={TabChange}
                />
                <div style={{ width: "100%" }}>
                    {
                        tab === 1 ?
                            <CustomTable
                                data={clients_data}
                                coll={['Name', ' Email', 'Phone']}
                            />
                            :
                            tab === 2 ?
                                <CustomTable
                                    data={carrers_data}
                                    coll={['Name', ' Email', 'Phone', 'File']}
                                />
                                : null
                    }
                </div>

            </div>
        </>

    );
}
}

export default AdminMain;