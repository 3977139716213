//import useState hook to create menu collapse state
import React, { useState } from "react";

//import react pro sidebar components
import { ProSidebar, SidebarHeader, SidebarContent, Menu, MenuItem, SidebarFooter } from 'react-pro-sidebar'

//import icons from react icons
import { FaList, FaTable, FaFilePdf } from "react-icons/fa";
import {
    FiHome,
    FiLogOut,
    FiArrowLeftCircle,
    FiArrowRightCircle
} from "react-icons/fi";
import { MdOutlineUnsubscribe } from "react-icons/md";

import "react-pro-sidebar/dist/css/styles.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const SideNavBar = ({ TabChange }) => {
    let navigate = useNavigate();

    const [menuCollapse, setMenuCollapse] = useState(false);
    const [state, setState] = useState(0)
    const menuIconClick = () => {
        menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
    };




    return (
        <>
            <div id="header">
                <ProSidebar collapsed={menuCollapse}>
                    <SidebarHeader>
                        <div className="logotext">
                            <p>{menuCollapse ? "" : "devQsoft"}</p>
                        </div>
                        <div className="closemenu" onClick={menuIconClick}>
                            {menuCollapse ? <FiArrowRightCircle /> : <FiArrowLeftCircle />}
                        </div>
                    </SidebarHeader>
                    <SidebarContent>
                        <Menu iconShape="square" >
                            <MenuItem className={state === 0 && 'menu_sideBar'} icon={<FiHome />} onClick={() => { TabChange(0); setState(0) }}>
                                Home
                            </MenuItem>
                            <MenuItem className={state === 1 && 'menu_sideBar'} icon={<FaList />} onClick={() => { TabChange(1); setState(1) }}>Clients</MenuItem>
                            <MenuItem className={state === 2 && 'menu_sideBar'} icon={<FaTable />} onClick={() => { TabChange(2); setState(2) }}>Carrers</MenuItem>
                            <MenuItem className={state === 3 && 'menu_sideBar'} icon={<MdOutlineUnsubscribe />} onClick={() => { TabChange(3); setState(3) }}>Subscribe</MenuItem>
                            {/* <MenuItem icon={<BiCog />}>Settings</MenuItem> */}
                        </Menu>
                    </SidebarContent>
                    <SidebarFooter>
                        <Menu iconShape="square">
                            <MenuItem icon={<FiLogOut />} onClick={() => { localStorage.clear(); navigate('/admin') }}>Logout</MenuItem>
                        </Menu>
                    </SidebarFooter>
                </ProSidebar>
            </div>
        </>
    );
};

export default SideNavBar;
