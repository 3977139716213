import React, { useEffect, useState } from "react";
//   is an ace end - to - end E - business solutions and software development company,
import img1 from "../Assets/Image/bullseye.png";
import img2 from "../Assets/Image/goal.png";
import img3 from "../Assets/Image/diamond.png";
import img4 from "../Assets/Image/trend.png";
import AOS from "aos";
import "aos/dist/aos.css";

//     offering technical solutions
const BusinessStrategy = () => {
  const [isHovered, setHovered] = useState(false);
  const [isHovered1, setHovered1] = useState(false);
  const [isHovered3, setHovered3] = useState(false);
  const [isHovered4, setHovered4] = useState(false);

  useEffect(() => {
    AOS.init({ duration: "2000", delay: "700" });
  });

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleMouseEnter1 = () => {
    setHovered1(true);
  };

  const handleMouseLeave1 = () => {
    setHovered1(false);
  };

  const handleMouseEnter3 = () => {
    setHovered3(true);
  };

  const handleMouseLeave3 = () => {
    setHovered3(false);
  };
  const handleMouseEnter4 = () => {
    setHovered4(true);
  };

  const handleMouseLeave4 = () => {
    setHovered4(false);
  };
  const textStyle = {
    color: isHovered ? "white" : "black",
  };
  const textStyle1 = {
    color: isHovered1 ? "white" : "black",
  };
  const textStyle3 = {
    color: isHovered3 ? "white" : "black",
  };
  const textStyle4 = {
    color: isHovered4 ? "white" : "black",
  };
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        marginTop: 50,
        marginBottom: 50,
      }}
    >
      <div data-aos="fade-up" className="text_ped">
        <div className="heading fs-25 mb-1" style={{ textAlign: "center" }}>
          Welcome to dev<span>&#8474;</span>soft
        </div>
        <div>
          <h2 className="section textColorOpacity  text_rounded">
            {" "}
            Development of Quality Software
          </h2>
        </div>
        <p className="section-2">
          development Quality Technologies Pvt. Ltd is an ace end-to-end
          E-business solutions and software development company, offering
          technical solutions.
        </p>
      </div>
      <div className="box_row">
        <div
          className="col-md-3 col-sm-3 col-xs-12"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div
            className="welcome-main wow animated fadeInUp"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div
              style={{ alignItems: "center", marginBottom: 15 }}
              className="box_image"
            >
              <img
                src={img1}
                style={{
                  height: 75,
                  width: 75,
                  resizeMode: "contain",
                  borderRadius: 100,
                }}
              />
            </div>
            <h4 style={textStyle}>MISSION</h4>
            <p style={textStyle}>
              Our mission is to provide scalable &amp; distinctive solutions and
              services, delivering long term commercial benefits to our clients
              based upon their complex business needs.
            </p>
          </div>
        </div>

        <div
          className="col-md-3 col-sm-3 col-xs-12"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div
            className="welcome-main wow2 animated fadeInUp"
            onMouseEnter={handleMouseEnter1}
            onMouseLeave={handleMouseLeave1}
          >
            <div
              style={{ alignItems: "center", marginBottom: 15 }}
              className="box_image2"
            >
              <img
                src={img2}
                style={{
                  height: 50,
                  width: 50,
                  resizeMode: "contain",
                }}
              />
            </div>
            <h4 style={textStyle1}>VISION</h4>
            <p style={textStyle1}>
              To design product-based services and solutions that not only meet
              rather exceed the customer expectations; including essential
              elements of quality and time at every point of time. To grow with
              our people and foster social &amp; ethical values to the work, we
              do.
            </p>{" "}
          </div>
        </div>

        <div
          className="col-md-3 col-sm-3 col-xs-12"
          data-aos="fade-up"
          data-aos-delay="500"
        >
          <div
            className="welcome-main wow3 animated fadeInUp"
            onMouseEnter={handleMouseEnter3}
            onMouseLeave={handleMouseLeave3}
          >
            <div
              style={{ alignItems: "center", marginBottom: 15 }}
              className="box_image3"
            >
              <img
                src={img3}
                style={{
                  height: 70,
                  width: 70,
                  resizeMode: "contain",
                  borderRadius: 100,
                }}
              />
            </div>
            <h4 style={textStyle3}>OUR VALUES</h4>
            <p style={textStyle3}>
              Our corporate values are Passion, Commitment, Integrity,
              Innovation, Teamwork and Trust. We believe in team work, knowledge
              sharing and nurturing care for our clients &amp; partners.
            </p>{" "}
          </div>
        </div>

        <div
          className="col-md-3 col-sm-3 col-xs-12"
          data-aos="fade-up"
          data-aos-delay="900"
          onMouseEnter={handleMouseEnter4}
          onMouseLeave={handleMouseLeave4}
        >
          <div className="welcome-main wow4 animated fadeInUp">
            <div
              style={{ alignItems: "center", marginBottom: 15 }}
              className="box_image4"
            >
              <img
                src={img4}
                style={{
                  height: 50,
                  width: 50,
                  resizeMode: "contain",
                }}
              />
            </div>
            <h4 style={textStyle4}>STRATEGY</h4>
            <p style={textStyle4}>
              Business Strategy: We believe the best strategies seem obvious as
              soon as you understand them. Strategy has to be easy to define.
              Winlancer Strategy basesd on Simple Method called IMO. I Sounds
              Identity, M Sounds Market, O Sounds Offering (Product &amp;
              Services)
            </p>{" "}
          </div>
        </div>
      </div>
      {/* <SliderComponent /> */}
    </div>
  );
};

export default BusinessStrategy;
