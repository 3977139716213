import React from 'react';

import './Bgvideo.css'; // Import your CSS file
import Carousel from '../Home/ImageScroll/Carousel';


const Bgvideo = () => {
  return (
    <div className="container-fluid">
      <div className="video-box">
    
        <video className="bannerVideo" autoPlay muted loop>
          <source src={'https://firebasestorage.googleapis.com/v0/b/devqsoft-e4595.appspot.com/o/video3.mp4?alt=media&token=a415c1e3-ec81-4bfd-9ced-d5f2c172ac01'} type="video/mp4" />
        </video>
        {/* <Carousel/> */}
      </div>
   
    </div>
  );
};

export default Bgvideo;
