import React, { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import HTML from "../../../Assets/Image/HTML5-Logo.webp";
import CSS from "../../../Assets/Image/CSS3.png";
import JAVASCRIPT from "../../../Assets/Image/js.png";
import REACT_JS from "../../../Assets/Image/Reactjs.png";
import REACT_NATIVE from "../../../Assets/Image/react-native1.png";
import NODE_JS from "../../../Assets/Image/node-js.png";
import GITHUB from "../../../Assets/Image/github-circle.svg";
import JAVA from "../../../Assets/Image/java-png.png";
import MONGO_DB from "../../../Assets/Image/Mongodb.png";
import C from "../../../Assets/Image/C_Logo.png";
import C2 from "../../../Assets/Image/C++.png";
import PHP from "../../../Assets/Image/PHP-.png";
import REDUX from "../../../Assets/Image/redux.png";
import AWS from "../../../Assets/Image/AWS-Logo.png";
import BOOTSTRAP from "../../../Assets/Image/Bootstrap.png";
import RIGHT from "../../../Assets/Image/Right_icon.png";
import LEFT from "../../../Assets/Image/Left_icon.png";

import "./Carousel.css";

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      // Your rotation logic here...
      const elems = document.getElementsByClassName("pics");
      const b = elems[0].className;
      elems[0].className = elems[1].className;
      elems[1].className = elems[2].className;
      elems[2].className = elems[3].className;
      elems[3].className = elems[4].className;
      elems[4].className = elems[5].className;
      elems[5].className = elems[6].className;
      elems[6].className = elems[7].className;
      elems[7].className = elems[8].className;
      elems[8].className = elems[9].className;
      elems[9].className = elems[10].className;
      elems[10].className = elems[11].className;
      elems[11].className = elems[12].className;
      elems[12].className = elems[13].className;
      elems[13].className = elems[14].className;
      elems[14].className = b;
    }, 550.5);

    
    const stopRotationTimeout = setTimeout(() => {
      clearInterval(interval); 
    }, 5000);

   
    return () => {
      clearInterval(interval);
      clearTimeout(stopRotationTimeout);
    };
  }, []); 


  const handleNextSlide = () => {
    const elems = document.getElementsByClassName("pics");
    const b = elems[0].className;
    elems[0].className = elems[1].className;
    elems[1].className = elems[2].className;
    elems[2].className = elems[3].className;
    elems[3].className = elems[4].className;
    elems[4].className = elems[5].className;
    elems[5].className = elems[6].className;
    elems[6].className = elems[7].className;
    elems[7].className = elems[8].className;
    elems[8].className = elems[9].className;
    elems[9].className = elems[10].className;
    elems[10].className = elems[11].className;
    elems[11].className = elems[12].className;
    elems[12].className = elems[13].className;
    elems[13].className = elems[14].className;
    elems[14].className = b;
  };
  const handlePreviousSlide = () => {
    const elems = document.getElementsByClassName("pics");
    const b = elems[14].className;
    elems[14].className = elems[13].className;
    elems[13].className = elems[12].className;
    elems[12].className = elems[11].className;
    elems[11].className = elems[10].className;
    elems[10].className = elems[9].className;
    elems[9].className = elems[8].className;
    elems[8].className = elems[7].className;
    elems[7].className = elems[6].className;
    elems[6].className = elems[5].className;
    elems[5].className = elems[4].className;
    elems[4].className = elems[3].className;
    elems[3].className = elems[2].className;
    elems[2].className = elems[1].className;
    elems[1].className = elems[0].className;
    elems[0].className = b;
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handlePreviousSlide(),
    onSwipedRight: () => handleNextSlide(),
  });

  return (
    <>
      <div className="container" {...handlers}>
        <div id="carousel">

          <div className="pics pic1">
            <div className="imagecontainer">
              <img style={{ height: "200px", width: "200px" }} src={HTML} />
              <p
                style={{
                  position: "absolute",
                }}
              >
                HTML
              </p>
            </div>
          </div>
          <div className="pics pic2">
            <div className="imagecontainer">
              <img style={{ height: "170px", width: "170px" }} src={CSS} />
              <p style={{ position: "absolute" }}> CSS </p>
            </div>
          </div>
          <div className="pics pic3">
            <div className="imagecontainer">
              <img
                style={{ height: "200px", width: "200px" }}
                src={JAVASCRIPT}
              />
            </div>
          </div>
          <div className="pics pic4">
            <div className="imagecontainer">
              <img style={{ height: "200px", width: "200px" }} src={REACT_JS} />
            </div>
          </div>
          <div className="pics pic5">
            <div className="imagecontainer">
              <img
                style={{ height: "200px", width: "200px" }}
                src={REACT_NATIVE}
              />
            </div>
          </div>
          <div className="pics pic6">
            <div className="imagecontainer">
              <img style={{ height: "200px", width: "200px" }} src={NODE_JS} />
            </div>
          </div>
          <div className="pics pic7">
            <div className="imagecontainer">
              <img style={{ height: "200px", width: "200px" }} src={GITHUB} />
            </div>
          </div>
          <div className="pics pic8">
            <div className="imagecontainer">
              <img style={{ height: "200px", width: "200px" }} src={JAVA} />
            </div>
          </div>
          <div className="pics pic9">
            <div className="imagecontainer">
              <img style={{ height: "200px", width: "200px" }} src={MONGO_DB} />
            </div>
          </div>
          <div className="pics pic10">
            <div className="imagecontainer">
              <img style={{ height: "200px", width: "200px" }} src={C} />
            </div>
          </div>
          <div className="pics pic11">
            <div className="imagecontainer">
              <img style={{ height: "200px", width: "200px" }} src={C2} />
            </div>
          </div>
          <div className="pics pic12">
            <div className="imagecontainer">
              <img style={{ height: "200px", width: "200px" }} src={PHP} />
            </div>
          </div>
          <div className="pics pic13">
            <div className="imagecontainer">
              <img style={{ height: "200px", width: "200px" }} src={REDUX} />
            </div>
          </div>
          <div className="pics pic14">
            <div className="imagecontainer">
              <img style={{ height: "200px", width: "200px" }} src={AWS} />
            </div>
          </div>
          <div className="pics pic15">
            <div className="imagecontainer">
              <img
                style={{ height: "200px", width: "200px" }}
                src={BOOTSTRAP}
              />
            </div>
          </div>
          <div className="controls">
  <img
    style={{
      height: 48,
      width: 808,
      marginRight: 10,
      transform: `rotate(${180}deg)`,
      border: "2px solid #000",
      borderRadius: "50%",
      filter: "brightness(0) invert(1)", 
    }}
    src={RIGHT}
    alt="Right Icon"
    onClick={() => handlePreviousSlide()}
  />
  <img
    style={{
      height: 48,
      width: 88,
      marginLeft: 10,
      border: "2px solid #000",
      borderRadius: "50%",
      transform: "rotate(180deg)",
      filter: "brightness(0) invert(1)",
    }}
    src={LEFT}
    alt="Left Icon"
    onClick={() => handleNextSlide()}
  />
  <div className="red-background"></div>
</div>
        

        </div>
   
      </div>
   
    </>
  );
};

export default Carousel;
