import React, { useEffect, useState } from "react";
import circle_filled from '../Assets/Image/circle-filled.png'
import carrears_img from '../Assets/Image/praca3.png'
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { Alert, ProgressBar, useAccordionButton } from "react-bootstrap";
import { uploadImageFireBase, PostData } from "../Utils/firebaseServices";
import { useRef } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from "./Home/Footer";
import CircleSlider from "./Circle/CircleSlider";        
import CardImage from "./Card/CardImage";
import fast_forward from "../Assets/Image/fast_forward.png"

const Carrers = () => {
    const ref = useRef()
    const [data, setData] = React.useState({});
    const [localUrl, setLocalUrl] = React.useState();
    const [message, setMessage] = React.useState({ error: false, msg: '' });
    const [Percente, setPercent] = React.useState(0)
    const [fileName, setFileName] = React.useState('')

    const [isVisible, setIsVisible] = useState(false);

    const goToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };
  
    useEffect(() => {
     
      goToTop();
  
      
      AOS.init({
        duration: "1000",
        delay: '100'
      });
    }, []);
  
  
    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 450) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
    // upload image in fireBase
    const uploadImage = (e) => {
        setMessage('')
        // e.preventDefault()
        const file = e.target.files[0]
        setFileName(file?.name)

        uploadImageFireBase(file, callBackUploadImage, call_backPercent)
    }

    // get reasponse and Get Url In fireBase
    const callBackUploadImage = (res) => {
        setLocalUrl(res)
        setPercent(0)
    }

    // // upload In firebase Percente

    const call_backPercent = (res) => {
        setPercent(res)
    }

    // upload data in fireBase
    const dataUploadInFireBase = (e) => {
        const emailValid = data?.email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);

        e.preventDefault();
        if (data?.name && data?.email && data?.phone && localUrl) {
            if (!emailValid) {
                setMessage({ error: false, msg: "Invalid Email Format!" });
                return;
            }
            const userData = {
                ...data,
                file: localUrl
            }
            PostData(
                userData,
                "carrers",
                responsePostData
            )
        } else {

            setMessage({ error: false, msg: "All field are mandatory!" });

            return;
        }

    }

    // response After Submit data
    const responsePostData = (res) => {

        if (res?.id) {
            setData({
                name: '',
                email: '',
                phone: ""
            })
            setLocalUrl()
            ref.current.value = ""
            setMessage({ error: true, msg: "Thank you for filling out your information!" });
            setTimeout(() => {
                setMessage("")
            }, 3000);

        } else {
            setMessage({ error: false, msg: "Sorry , Network Error" });
        }
    }
    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey, () =>
            console.log('totally custom!', eventKey),
        );

        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", color: "#2A72C5 ", fontWeight: "700" }} onClick={decoratedOnClick}>
                <img src={circle_filled} style={{ objectFit: 'contain', marginRight: 5 }} />
                {children}
            </div >
        );
    }

    useEffect(()=>{
        AOS.init({duration:"1000",
     delay:'100'}) 
     })
    return (
        <>



            <div style={{ marginBottom: 100 }}>

                <CardImage/>
                <div className="carrers_dashboard">
                    <div>
                        <h2 className="separator_on" >At devQsoft everyone has the opportunity to develop within the framework of project or organizational structures.</h2>
                        <div style={{ height: 3, backgroundColor: "#dd9933", width:"100%", marginTop: 30, marginBottom: 30 }} />
                        <h6 style={{textAlign:'center'}} >We will encourage and support you during your personal and professional development.<p></p>
                        <p style={{textAlign:'center'}} >We will always balance our need to hire external specialists against training and promoting an existing devQsoft  team member.</p></h6>
                    </div>
                    
                    <div style={{backgroundColor:"#f2f2f2",   backdropFilter: 'blur(10px)' , boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.1)'}}  className="sliderRound" >
                 {/* <center><img src={carrears_img} style={{ height: 500, objectFit: 'contain', }}  data-aos="zoom-in-up" data-aos-delay="500" /></center> */}
                 <CircleSlider  />
                    </div>
                </div >
                <div style={{ flexDirection: "row", display: "flex", justifyContent: "space-around", flexFlow: "wrap", marginTop:40 }}>
                    <div style={{ minWidth: "40%", margin:"15px" }}>

                        <h3>job opportunities</h3>
                        <div>
                            <Accordion >
                                <Card style={{ marginBottom: 10 }}>
                                    <Card.Header style={{ backgroundColor: "#fff" }}>
                                        <CustomToggle eventKey="0"> Software Engineer Trainee</CustomToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            Qualifications : BE/B.Tech/MCA<br />
                                            Experience : Freshers<br />
                                            Should have good knowledge about.NET/PHP/WORDPRESS/ANDROID</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header style={{ backgroundColor: "#fff" }}>
                                        <CustomToggle eventKey="1">React Native Developer</CustomToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="1">
                                        <Card.Body>
                                            Qualifications : BE/B.Tech/MCA<br />
                                            Experience : 0 to 5 Year<br />
                                            Should have good knowledge about HTML/CSS/JAVASCRIPT/ANDROID/IOS</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>
                    </div>

                    <div style={{ minWidth: "30%",  marginBottom:"-84px"}}>
                        <h3> Get in Touch</h3>
                        {message?.msg && (<Alert variant={message?.error ? "success" : 'danger'} dismissible onClose={() => setMessage('')}>
                            {' '}
                            {message?.msg}
                        </Alert>)}
                        {
                            Percente > 0 && <ProgressBar animated now={Percente} label={`${Percente}%`} style={{ marginBottom: 20 }} />

                        }
                        <input className="form-control" type="text" name="name" id="name" placeholder="Name *" value={data?.name} onChange={(e) => {
                            setData({ ...data, name: e.target.value });
                            setMessage('')
                        }} />
                        <input className="form-control" type="number" placeholder="Number *" value={data?.phone} onChange={(e) => {
                            setData({ ...data, phone: e.target.value });
                            setMessage('')
                        }} />
                        <input className="form-control" type="text" placeholder="Email *" value={data?.email} onChange={(e) => {
                            setData({ ...data, email: e.target.value });
                            setMessage('')
                        }} />
                        <input name="ufile" type="file" id="ufile" size="40" className="form-control" placeholder="Upload the file here"
                            accept=".pdf"
                            ref={ref}
                            style={{ padding: 22 }} onChange={(e) => uploadImage(e)} />


                        <button type="submit" value="SEND" id="submit" name="submit_btn" className="btn-light " onClick={dataUploadInFireBase}>Send Now</button>

                    </div >
                </div >
            </div >
            <Footer />
            {isVisible &&
        <div id='btn-back-to-top'>
          <img src={fast_forward} alt="Back to Top" style={{
            height: 30, width: 30, resizeMode: 'contain', borderRadius: 100,
          }} />
        </div>
      }
        </>

    )
}

export default Carrers;
