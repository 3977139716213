import "./App.css";

import MainRoutes from "./Route/MainRoutes";
import "bootstrap/dist/css/bootstrap.min.css";




function App() {
  return (
    <>
      <MainRoutes />

    </>
  );
}

export default App;
