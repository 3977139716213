import React, { useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useSpring, animated } from 'react-spring';
// import ReactParticles from 'react-particles-js';
// import particlesConfig from './particles-config.js';

import './styles.scss';

const CardImage = () => (
  <div className="main">
    {/* <Particles> */}
      <Hero>
        <div className="container-fluid">
          <div className="row">
            {cards.map((card, i) => (
              <div className="col-md-4" key={i}>
                <Card>
                  <div className="card-title">{card.title}</div>
                  <div className="card-body">{card.description}</div>
                  <Image ratio={card.imageRatio} src={card.image} />
                </Card>
              </div>
            ))}
          </div>
        </div>
      </Hero>
    {/* </Particles> */}
  </div>
);

const Card = ({ children }) => {
  const ref = useRef();
  const [isHovered, setHovered] = useState(false);

  const [animatedProps, setAnimatedProps] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 10, tension: 400, friction: 40, precision: 0.00001 }
  }));

  return (
    <animated.div
      ref={ref}
      className="card"
      onMouseEnter={() => setHovered(true)}
      onMouseMove={({ clientX, clientY }) => {
        const x =
          clientX -
          (ref.current.offsetLeft -
            (window.scrollX || window.pageXOffset || document.body.scrollLeft));

        const y =
          clientY -
          (ref.current.offsetTop -
            (window.scrollY || window.pageYOffset || document.body.scrollTop));

        const dampen = 50;
        const xys = [
          -(y - ref.current.clientHeight / 2) / dampen,
          (x - ref.current.clientWidth / 2) / dampen,
          1.07
        ];

        setAnimatedProps({ xys: xys });
      }}
      onMouseLeave={() => {
        setHovered(false);
        setAnimatedProps({ xys: [0, 0, 1] });
      }}
      style={{
        zIndex: isHovered ? 2 : 1,
        transform: animatedProps.xys.interpolate(
          (x, y, s) =>
            `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`
        )
      }}
    >
      {children}
    </animated.div>
  );
};

// const Particles = ({ children }) => (
//   <div style={{ position: 'relative' }}>
//     <ReactParticles
//       params={particlesConfig} 
//       style={{
//         position: 'absolute',
//         zIndex: 1,
//         left: 0,
//         right: 0,
//         bottom: 0,
//         top: 0
//       }}
//     />
//     {children && <div style={{ position: 'relative' }}>{children}</div>}
//   </div>
// );

const Hero = ({ children }) => (
  <div className="hero">
    <div className="hero-body">{children}</div>
  </div>
);

const Image = ({ ratio, src }) => (
  <div className="image-container">
    <div className="image-inner-container">
      <div
        className="ratio"
        style={{
          paddingTop: ratio * 100 + '%'
        }}
      >
        <div className="ratio-inner">
          <img src={src} alt={`Card ${ratio}`} />
        </div>
      </div>
    </div>
  </div>
);

const cards = [
  {
    title: 'Build faster ⚡️',
    description:
      'Create a React web app in the fraction of the time using our library of themes and building blocks. We have everything from navbars and content grids to authentication flows and commenting systems. New blocks are added every week.',
    image: 'https://6jlvz1j5q3.csb.app/undraw_collection.svg',
    imageRatio: 784 / 1016
  },
  {
    title: 'Tweak anything 👩‍🎨',
    description:
      'Built with developers in mind. Change element structure, edit CSS, create components, add props and state. We give you access to the underlying React code so you can do what you need right in our tool.',
    image: 'https://6jlvz1j5q3.csb.app/undraw_upload.svg',
    imageRatio: 839 / 1133
  },
  {
    title: 'Export your code 🚀',
    description:
      "Export your project as a high-quality React codebase. We're laser-focused on helping you build and iterate quickly, but expect that you'll eventually want to export and wrap things up in your favorite code editor.",
    image: 'https://6jlvz1j5q3.csb.app/undraw_static_assets.svg',
    imageRatio: 730 / 1030
  }
];

export default CardImage;
