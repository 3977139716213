import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"
const firebaseConfig = {
    apiKey: "AIzaSyDE-DseRwocLpabw-Y8b_lKE0vrxoF8t9o",
    authDomain: "devqsoft-e4595.firebaseapp.com",
    projectId: "devqsoft-e4595",
    storageBucket: "devqsoft-e4595.appspot.com",
    messagingSenderId: "360261073093",
    appId: "1:360261073093:web:4f1a3db0b2fc65ca4458ac"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app)
export const storage = getStorage(app);
export const auth = getAuth(app);
