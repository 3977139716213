
import Table from 'react-bootstrap/Table';
import { FaFilePdf } from 'react-icons/fa';



const CustomTable = ({ data, coll }) => {

    return (
        <div style={{ width: "100%" }}>

            <Table striped bordered hover >
                <thead >
                    <tr>
                        <th>Sr.n.</th>
                        {
                            coll.map((item, index) => (
                                <th key={index}>{item}</th>
                            ))
                        } </tr>
                </thead>
                {
                    data.map((item, index) => {
                        return (
                            <tbody key={index}>

                                <tr style={{ height: 60 }}>
                                    <td>{index + 1}</td>
                                    <td>{item?.name}</td>
                                    <td>{item?.email}</td>
                                    <td>{item?.phone}</td>
                                    {item?.file && <td > <a href={item?.file} target="_blank"><FaFilePdf style={{ minHeight: 40, minWidth: 40 }} /></a></td>}

                                </tr>

                            </tbody>

                        )
                    })
                }
            </Table>
        </div >

    )
}

export default CustomTable