import React, { useEffect } from 'react'

import './CircleSlider.css'
import AOS from 'aos';

import image1 from '../../Assets/Image/java-png.png';
import image2 from '../../Assets/Image/JAVA.jpg';
import image3 from '../../Assets/Image/CSS3.png';
import image4 from '../../Assets/Image/AWS-Logo.png';
import image5 from '../../Assets/Image/Bootstrap.png';
import image6 from '../../Assets/Image/github.png';
import image7 from '../../Assets/Image/Reactjs.png';
import image8 from '../../Assets/Image/devqsoftLogo.jpeg';
import image9 from '../../Assets/Image/devqsoft.jpg';
import Aos from 'aos';



const CircleSlider = () => {

  useEffect(()=>{
    Aos.init({duration:"1000",
 delay:'100'}) 
 })
  return (

    <div className="orbit"   >
      <div className="center-image">
        <img src={image8} alt="" style={{ borderRadius: "100px", padding: "10px" }} />
        <img src={image9} alt="" style={{ borderRadius: "100px", padding: "10px" }} />
      </div>
      <ul>
        <li>
          <div><img src={image2} alt="" style={{ borderRadius: "100px", padding: "10px" }} /></div>
          <p>Analytics &amp; Reporting</p>
        </li>
        <li>
          <div><img src={image3} alt="" style={{ borderRadius: "100px", padding: "10px" }} /></div>
          <p>Time &amp; Attendance</p>
        </li>
        <li>
          <div><img src={image4} alt="" style={{ borderRadius: "100px", padding: "10px" }} /></div>
          <p>Onboarding</p>
        </li>
        <li>
          <div><img src={image5} alt="" style={{ borderRadius: "100px", padding: "10px" }} /></div>
          <p>HR Information System</p>
        </li>
        <li>
          <div><img src={image6} alt="" style={{ borderRadius: "100px", padding: "10px" }} /></div>
          <p>Leaves &amp; Holidays</p>
        </li>
        <li>
          <div><img src={image7} alt="" style={{ borderRadius: "100px", padding: "10px" }} /></div>
          <p>Exit &amp; Off-boarding</p>
        </li>
        <li>
          <div><img src={image2} alt="" style={{ borderRadius: "100px", padding: "10px" }} /></div>
          <p>Travel &amp; Requisitions</p>
        </li>
        <li>
          <div><img src={image1} alt="" style={{ borderRadius: "100px", padding: "10px" }} /></div>
          <p>Disciplinary</p>
        </li>
      </ul>
    </div>

  )
}

export default CircleSlider